import { ForbiddenRequestError, InvalidRequestError, NotFoundRequestError, ServerError, UnauthorizedRequestError } from "./error"

export function assertResponse(res: Response, result?: any) {
  switch (res.status) {
    case 400:
      throw new InvalidRequestError(result)
    case 401:
      throw new UnauthorizedRequestError(res);
    case 404:
      throw new NotFoundRequestError(res);
    case 403:
      throw new ForbiddenRequestError(res);
  }

  if (res.status >= 500) {
    throw new ServerError(res)
  }
}