import "./scss/main.scss";
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "./resources/favicon.png";

import "./globals";

import { Config } from "./config";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { configureStore } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

Sentry.init({
  dsn: Config.sentryDSN,
  release: `${Config.appVersion ?? "0.0.0"}`,
  environment: Config.sentryEnvironment,
  integrations: [
    Sentry.rewriteFramesIntegration({
      prefix: "~",
      root: Config.basePath,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: Config.sentryTracesSampleRate,
  normalizeDepth: 8,
});

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("app")
);

if ((module as any).hot) {
  (module as any).hot.accept();
}
