import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { CreateObjectRequestAction, CREATE_OBJECT_FAILURE, CREATE_OBJECT_REQUEST, CREATE_OBJECT_SUCCESS, DeleteObjectRequestAction, DELETE_OBJECT_FAILURE, DELETE_OBJECT_REQUEST, DELETE_OBJECT_SUCCESS, FetchObjectRequestAction, FETCH_OBJECT_FAILURE, FETCH_OBJECT_REQUEST, FETCH_OBJECT_SUCCESS, QueryObjectsRequestAction, QUERY_OBJECTS_FAILURE, QUERY_OBJECTS_REQUEST, QUERY_OBJECTS_SUCCESS, UpdateObjectRequestAction, UPDATE_OBJECT_FAILURE, UPDATE_OBJECT_REQUEST, UPDATE_OBJECT_SUCCESS, fetchObject, FETCH_OBJECT_EVENT_REQUEST, fetchObjectEvents, FetchObjectEventsRequestAction, FETCH_OBJECT_EVENT_FAILURE, FETCH_OBJECT_EVENT_SUCCESS } from '../actions/objects'
import { Config } from '../config'
import { selectIdToken } from '../selectors/session'
import { ObjectHeader, ObjectServerClient, StoreObject } from '../util/objects'
import { handleUnauthorizedRequestError } from './helper'

export function* objectsSaga(): Generator {
  yield all([
    takeEvery(QUERY_OBJECTS_REQUEST, queryObjectsSaga),
    takeEvery(CREATE_OBJECT_REQUEST, createObjectsSaga),
    takeEvery(UPDATE_OBJECT_REQUEST, updateObjectSaga),
    takeEvery(FETCH_OBJECT_REQUEST, fetchObjectsSaga),
    takeEvery(DELETE_OBJECT_REQUEST, deleteObjectSaga),
  ])
}

function* queryObjectsSaga(action: QueryObjectsRequestAction): Generator<any, any, any> {
  const accessToken = yield select(selectIdToken)
  const client = new ObjectServerClient(Config.objectServerURL, accessToken as string)

  let objects: (ObjectHeader | StoreObject)[]
  try {
    objects = yield call(client.queryObjects, action.namespace, action.privacyDomain, action.filter, action.options)
  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, error, action)
    if (handled) return

    yield put({ type: QUERY_OBJECTS_FAILURE, txId: (action as any).txId, error })
    return
  }

  yield put({ type: QUERY_OBJECTS_SUCCESS, txId: (action as any).txId, namespace: action.namespace, privacyDomain: action.privacyDomain, objects })
}

function* createObjectsSaga(action: CreateObjectRequestAction): Generator<any, any, any> {
  const accessToken = yield select(selectIdToken)
  const client = new ObjectServerClient(Config.objectServerURL, accessToken as string)

  let object
  try {
    object = yield call(client.createObject, action.namespace, action.privacyDomain, action.object, action.options?.tenant)
  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, error, action)
    if (handled) return

    yield put({ type: CREATE_OBJECT_FAILURE, txId: (action as any).txId, error })
    return
  }

  yield put({ type: CREATE_OBJECT_SUCCESS, txId: (action as any).txId, namespace: action.namespace, privacyDomain: action.privacyDomain, object })
}

function* updateObjectSaga(action: UpdateObjectRequestAction): Generator<any, any, any> {
  const accessToken = yield select(selectIdToken)
  const client = new ObjectServerClient(Config.objectServerURL, accessToken as string)

  let object
  try {
    object = yield call(client.updateObject, action.namespace, action.privacyDomain, action.id, action.object, action.partial, action.options?.tenant)
  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, error, action)
    if (handled) return

    yield put({ type: UPDATE_OBJECT_FAILURE, txId: (action as any).txId, error })
    return
  }

  yield put({ type: UPDATE_OBJECT_SUCCESS, txId: (action as any).txId, namespace: action.namespace, privacyDomain: action.privacyDomain, object })
}

function* fetchObjectsSaga(action: FetchObjectRequestAction): Generator<any, any, any> {
  const accessToken = yield select(selectIdToken)
  const client = new ObjectServerClient(Config.objectServerURL, accessToken as string)

  let object
  try {
    object = yield call(client.fetchObject, action.namespace, action.privacyDomain, action.id, action.options?.tenant)
  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, error, action)
    if (handled) return

    yield put({ type: FETCH_OBJECT_FAILURE, txId: (action as any).txId, error })
    return
  }

  yield put({ type: FETCH_OBJECT_SUCCESS, txId: (action as any).txId, namespace: action.namespace, privacyDomain: action.privacyDomain, object })
}

function* deleteObjectSaga(action: DeleteObjectRequestAction): Generator<any, any, any> {
  const accessToken = yield select(selectIdToken)
  const client = new ObjectServerClient(Config.objectServerURL, accessToken as string)

  try {
    yield call(client.deleteObject, action.namespace, action.privacyDomain, action.id, action.options?.tenant)
  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, error, action)
    if (handled) return

    yield put({ type: DELETE_OBJECT_FAILURE, txId: (action as any).txId, error })
    return
  }

  yield put({ type: DELETE_OBJECT_SUCCESS, txId: (action as any).txId, namespace: action.namespace, privacyDomain: action.privacyDomain, id: action.id })
}

function* fetchObjectEventsSaga(action: FetchObjectEventsRequestAction): Generator<any, any, any> {
  const accessToken = yield select(selectIdToken)
  const client = new ObjectServerClient(Config.objectServerURL, accessToken as string)
  const object = action.object
  let objects
  try {
    objects = yield call(client.fetchObjectEvents, action.namespace, action.privacyDomain, action.id)
  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, error, action)
    if (handled) return

    yield put({ type: FETCH_OBJECT_EVENT_FAILURE, txId: (action as any).txId, error })
    return
  }
  yield put({ type: FETCH_OBJECT_EVENT_SUCCESS, txId: (action as any).txId, namespace: action.namespace, privacyDomain: action.privacyDomain, objects, object })
}
