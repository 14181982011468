import { Action } from "redux";
import {
  CHECK_AUTHZ_REQUEST_SUCCESS,
  CheckAuthzActionSuccess,
} from "../actions/authz";

export interface AuthzState {
  byModules: IndexedModule;
}

export interface IndexedModule {
  [key: string]: AuthzResource;
}

interface AuthzResource {
  [action: string]: {
    [resourceId: string]: boolean;
  };
}

const defaultState = {
  byModules: {},
};

export function authzReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case CHECK_AUTHZ_REQUEST_SUCCESS:
      return handleCheckAuthzSuccess(state, action as CheckAuthzActionSuccess);
  }

  return state;
}

function handleCheckAuthzSuccess(
  state: AuthzState,
  action: CheckAuthzActionSuccess
) {
  const newState = { ...state };
  const module = {
    ...newState.byModules[action.result.authzResource.kind],
  } as AuthzResource;
  module[action.result.authzAction] = {
    ...module[action.result.authzAction],
    [action.result.authzResource.id]: action.result.allowed,
  };
  newState.byModules[action.result.authzResource.kind] = module;
  return newState;
}
